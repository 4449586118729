.App {
  width: 100%;
  text-align: center;
  padding: 20px;
  padding-top: 60px;
  min-height: 88vh;
}

form {
  margin-bottom: 20px;
}

input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
}

button {
  padding: 10px 20px;
  font-size: 16px;
}

.output {
  background-color: #f0f0f0;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  width: 80%;
  text-align: left;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}