/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    width: 100%;
    margin-bottom: 10px;
}

.header__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header__nav-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.header__nav-item {
    margin-left: 20px;
    margin-right: 20px;
}

.header__nav-link {
    text-decoration: none;
    color: #000;
    font-size: 16px;
}

.header__right {
    display: flex;
    align-items: center;
}

.header__icon {
    margin-left: 20px;
    text-decoration: none;
    color: #000;
    font-size: 16px;
}

.header__menu-icon {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

.username-button {
    width: auto;
    height: 45px;
    border-radius: 7px;
    padding-left: 25px;
    padding-right: 25px;
}

.username-button:hover {
    background-color: #dddddd;
}

.theme-toggle-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #3a211a;
}