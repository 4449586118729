.video-list {
    width: 80%;
    /* display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between; */

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between the grid items */
  }
  
  .video-frame {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center both video and button */
    justify-content: center;
    width: 100%;
    height: auto; /* Allow flexible height */
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 10px;
  }
  
  .video {
    max-width: 100%;
    max-height: 100%;
  }

  .download-button {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .download-button:hover {
    background-color: #45a049;
  }